import type { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { CompanyConstant } from "./CompanyConstant";
import { DECIMAL_2 } from "presentation/utils/numberUtil";

const COMPANY_DETAIL_CONSTANT = CompanyConstant.Detail;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_COMPANY_EMPTY_POOL_COL_DEF: any[] = [
    {
        headerName: COMPANY_DETAIL_CONSTANT.PRIORITY,
        field: 'priority',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        dataType: "number",
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.STATUS,
        field: 'status',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 100,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.EFFECTIVE_DATE,
        field: 'effectiveDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "date"
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.OPERATING_TML,
        field: 'operatingTml',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.EMPTY_POOL.POOL_TYPE,
        field: 'poolType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.UOM,
        field: 'uom',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 100,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.EMPTY_POOL.CALCULATE_BY,
        field: 'calculateBy',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.EMPTY_POOL.POOL_SIZE,
        field: 'poolSize',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CONFIRM_DATE,
        field: 'confirmedDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "date"
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.REMARKS,
        field: 'remarks',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.REJECT_REASON,
        field: 'rejectReason',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.ACTIVE_IND,
        field: 'activeInd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    }

]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}