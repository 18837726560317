import { SelectionChangedEvent } from "ag-grid-community";
import { CompanyConstant } from "presentation/constant/Company/CompanyConstant";
import { INITIAL_CUSTOMER_ASSIGNMENT_COL_DEF, transferRowData } from "presentation/constant/Company/CustomerAssignmentColumnDefinition";
import { useCustomerAssignmentVM } from "presentation/hook/Company/useCustomerAssignmentVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";


const CustomerAssignmentTablePanel: React.FC = () => {
    const [companyDetailState] = useCompanyDetailTracked();
    const customerAssignmentVM = useCustomerAssignmentVM();
    const messageBarVM = useMessageBarVM();

    const [isLoading, setIsLoading] = useState(false);
    const { selectedDatas } = companyDetailState.customerAssignmentState;
    const COMPANY_DTL_CONST = CompanyConstant.Detail;
    const { isAdd, isEditable, isEditCompDtlInfo, isShowFsp, isShowEmptyPool, isShowSuppleTerms, isShowCustAssign, isShowCustInfo, isShowFactor } = companyDetailState.viewState;
    // const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    let gridRef: any = useRef();

    const memoTableBtns = useMemo(() => {
        const tableBtns = [];

        tableBtns.push({
            id: 'onRefreshButton',
            icon: 'Icon-reload',
            title: 'Refresh'
        })

        tableBtns.push({
            id: 'onEditCustInfoButton',
            icon: 'Icon-info',
            title: 'Customer Information',
            disable: isAdd || isEditable
        })

        /*if (isEditCompDtlInfo) {
            if (!(isAdd || isEditable)) {*/
                tableBtns.push({
                    id: 'onDeleteButton',
                    icon: 'Icon-trash',
                    title: 'Delete',
                    disable: !(isEditCompDtlInfo && !(isAdd || isEditable))
                })

                tableBtns.push({
                    id: 'onAddButton',
                    icon: 'Icon-add',
                    title: 'Add',
                    disable: !(isEditCompDtlInfo && !(isAdd || isEditable))
                })
        /*    }
        }*/

        return tableBtns
    }, [isAdd, isEditCompDtlInfo, isEditable])

    const handleAddClick = useCallback(async () => {
        customerAssignmentVM.onAdd();
    }, [customerAssignmentVM])

    const handleCustInfo = useCallback(() => {
        if (selectedDatas.length === 0 || selectedDatas.length > 1) {
            messageBarVM.showError("Please select a record.");
            return;
        }

        if (selectedDatas[0].customer) {
            customerAssignmentVM.onEditCustInfo(selectedDatas[0].customer);
        }
    }, [customerAssignmentVM, messageBarVM, selectedDatas]);

    const handleDelete = useCallback(() => {
        if (selectedDatas.length < 1) {
            messageBarVM.showError("Please select a record(s) to delete.");
            return;
        }

        customerAssignmentVM.handleDelete();
    }, [customerAssignmentVM, messageBarVM, selectedDatas]);

    const handleRefresh = useCallback(() => {
        setIsLoading(true);
        companyDetailState.companyId && customerAssignmentVM.initialCustAssignTableData(companyDetailState.companyId).then(() => {
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false)
        })
    }, [companyDetailState.companyId, customerAssignmentVM]);

    // let performedClicks = 0;
    const handleSelectionChange = useCallback((e: SelectionChangedEvent, delay: number = 250) => {
        if(isAdd) return;
        
        const selectedRows = e.api.getSelectedRows();
        customerAssignmentVM.onSelectedCustAssigns(selectedRows);
    }, [customerAssignmentVM, isAdd])

    const isRowSelectable = useCallback((params: any) => {
        if (((isShowCustAssign || isShowCustInfo) && (isEditable || isAdd))
                || isShowFsp || isShowEmptyPool || isShowSuppleTerms || isShowFactor) {
            return false;            
        } else {
            return true;            
        }
    }, [isAdd, isEditable, isShowCustAssign, isShowCustInfo, isShowEmptyPool, isShowFactor, isShowFsp, isShowSuppleTerms]);

    const memoTableTitle = useMemo(() =>
        <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
            <HeaderTitle>{COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_ASSIGN}</HeaderTitle>
        </Sidebarheader>
        , [COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_ASSIGN])

    const memoCustomerAssignMentTable = useMemo(() => {
        return (
            <div style={{ marginTop: "50px" }}>
                <NbisTable
                    id='customer-assignment-table'
                    isNewColumnSetting={true}
                    columns={INITIAL_CUSTOMER_ASSIGNMENT_COL_DEF?.slice()}
                    data={transferRowData(companyDetailState.companyCustomers) ?? []}
                    headerActionButtons={memoTableBtns}
                    showPaginator={false}
                    editable={false}
                    showHeaderIcons={true}
                    showAddIcon={false}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    isScrollHighlighted={true}
                    isRowHighligted={true}
                    selectionMode={false}
                    rowSelection={"multiple"}
                    //onRowDoubleClick={(e: any, row: CompanyCustomerEntity) => handleEdit(row)}
                    onAddButton={handleAddClick}
                    handleSelectionChanged={(e: any) => handleSelectionChange(e, 400)}
                    onRefreshButton={handleRefresh}
                    onDeleteButton={handleDelete}
                    onEditCustInfoButton={handleCustInfo}
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 700px)"
                    handleRowSelectable={isRowSelectable}

                    ref={gridRef}
                    headerLabel={memoTableTitle}
                    showPivotMode={false}
                />
            </div>
        );
    }, [companyDetailState.companyCustomers, handleAddClick, handleCustInfo, handleDelete, handleRefresh, handleSelectionChange, isRowSelectable, memoTableBtns, memoTableTitle])


    useEffect(() => {
        if (gridRef.current && gridRef.current.gridRef && gridRef.current.gridRef.current && gridRef.current.gridRef.current.api) {
            if (isShowFsp || isShowEmptyPool || isShowSuppleTerms || isShowFactor || isAdd) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
            gridRef.current.gridRef.current.api.redrawRows();
        }
    }, [isAdd, isEditable, isShowCustAssign, isShowEmptyPool, isShowFactor, isShowFsp, isShowSuppleTerms])

    return <><TableWrapper>{memoCustomerAssignMentTable}</TableWrapper>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />} </>;
}

export default memo(CustomerAssignmentTablePanel);
