import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_COMPANY_COL_DEF, transferRowData } from "presentation/constant/Company/CompanyColumnDefinition";
import { useCompanyMaintenanceVM } from "presentation/hook/Company/useCompanyMaintenanceVM";
import { useCompanyMaintenanceTracked } from "presentation/store/Company/CompanyMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { useCallback, useEffect, useMemo, useRef } from "react";


const CompanyTablePanel: React.FC = () => {
    const [companyState] = useCompanyMaintenanceTracked();
    const companyMaintenanceVM = useCompanyMaintenanceVM();

    //const { selectCompanyRows } = companyState;
    let gridRef: any = useRef();

    const handleDoubleClick = useCallback((e: any) => {
        companyMaintenanceVM.onDetail(e.data);
    }, [companyMaintenanceVM])

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        companyMaintenanceVM.onSelectedCompanys(selectedRows);
    }, [companyMaintenanceVM])

    const isRowSelectable = useCallback((params: any) => {
        if (companyState.viewState.isAdd || companyState.viewState.isEditable) {
            return false;
        /*} else if (companyState.viewState.isEditable && selectCompanyRows && selectCompanyRows.length > 0) {
            const findRow = selectCompanyRows.find((row: any) => row.id === params.data.id);
            if (!findRow) {
                return !companyState.viewState.isEditable;
            }*/
        } else {
            return true;
        }
    }, [companyState.viewState.isAdd, companyState.viewState.isEditable]);

    const memoCompanyTable = useMemo(() => {
        return (
            <>
                <NbisTable
                    id='company-maintenance-table'
                    isNewColumnSetting={true}
                    columns={INITIAL_COMPANY_COL_DEF?.slice()}
                    data={transferRowData(companyState.companys) ?? []}
                    showPaginator={false}
                    editable={false}
                    showHeaderIcons={true}
                    showAddIcon={false}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    isScrollHighlighted={true}
                    isRowHighligted={true}
                    selectionMode={false}
                    rowSelection={"multiple"}
                    handleSelectionChanged={handleSelectionChange}
                    onRowDoubleClick={handleDoubleClick}
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 125px)"
                    ref={gridRef}
                    handleRowSelectable={isRowSelectable}
                />
            </>
        );
    }, [companyState.companys, handleDoubleClick, handleSelectionChange, isRowSelectable])

    useEffect(() => {
        if (gridRef.current && gridRef.current.gridRef && gridRef.current.gridRef.current && gridRef.current.gridRef.current.api) {
            gridRef.current.gridRef.current.api.redrawRows();
        }
    }, [companyState.viewState.isAdd, companyState.viewState.isEditable])

    return <><TableWrapper>{memoCompanyTable}</TableWrapper></>;
}

export default CompanyTablePanel;
