import type { CustomCellRendererProps } from "ag-grid-react";
import { DECIMAL_2 } from "presentation/utils/numberUtil";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { CompanyConstant } from "./CompanyConstant";

const COMPANY_DETAIL_CONSTANT = CompanyConstant.Detail;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_CHARGE_SUPPLE_TERMS_COL_DEF: any[] = [
    {
        headerName: COMPANY_DETAIL_CONSTANT.EFFECTIVE_DATE,
        field: 'effectiveDate',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        dataType: "date"
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.OPERATING_TML,
        field: 'operatingTml',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CHARGE_SUPPLE_TERMS.SUPPLEMENTARY_TERMS,
        field: 'supplementaryTerms',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 210,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CHARGE_SUPPLE_TERMS.SUPPLEMENTARY_TERMS_REF,
        field: 'supplementaryTermsRef',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 240,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CHARGE_SUPPLE_TERMS.MODALITY,
        field: 'modality',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CHARGE_SUPPLE_TERMS.VALUE,
        field: 'value',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 100,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CONFIRM_DATE,
        field: 'confirmedDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "date"
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.STATUS,
        field: 'status',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 100,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CNTR_MOVEMENT,
        field: 'cntrMovement',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CNTR_STATUS,
        field: 'cntrStatus',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CHARGE_TYPE,
        field: 'chargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.SUB_CHARGE_TYPE,
        field: 'subChargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.REMARKS,
        field: 'remarks',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.REJECT_REASON,
        field: 'rejectReason',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.ACTIVE_IND,
        field: 'activeInd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    }

]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}