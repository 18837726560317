import { SupplementaryTermsPolicy } from "constants/company/SupplementaryTermsPolicy";
import { CompanyChargeSuppleTermsEntity, EMPTY_COMPANY_CHARGE_SUPPLE_TERMS_ENTITY } from "domain/entity/Company/CompanyChargeSuppleTermsEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { ShiftRepository } from "domain/repository/Common/ShiftRepo";
import { CompanyChargeSuppleTermsRepository } from "domain/repository/Company/CompanyChargeSuppleTermsRepo";
import type { CompanyTerminalRepository } from "domain/repository/Company/CompanyTerminalRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import _ from "lodash";
import { ShiftType } from "presentation/constant/Common/ShiftType";
import { commonCompChgSuppleTermValidationSchema_1, commonCompChgSuppleTermValidationSchema_2 } from "presentation/constant/Company/CompChgSuppleTermValidationSchema";
import { BillingTypeDroOpts } from "presentation/constant/DropDownOptions/Charge/BillingTypeDroOpts";
import { CntrMovementDroOpts } from "presentation/constant/DropDownOptions/Company/CntrMovementDroOpts";
import { CntrStatusDroOpts } from "presentation/constant/DropDownOptions/Company/CntrStatusDroOpts";
import { SupplementaryTermsDroOpts } from "presentation/constant/DropDownOptions/Company/SupplementaryTermsDroOpts";
import { SuppleTermsRefDroOpts } from "presentation/constant/DropDownOptions/Company/SuppleTermsRefDroOpts";
import { Validation } from "presentation/constant/Validation";
import { CompanyDetailModel } from "presentation/model/Company/CompanyDetailModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";

interface ChargeSuppleTermsVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<CompanyDetailModel>> | ((value: SetStateAction<CompanyDetailModel>) => void),
    ]
    companyChargeSuppleTermsRepo: CompanyChargeSuppleTermsRepository,
    masterDataRepo: MasterDataRepository,
    shiftRepo: ShiftRepository,
    parameterDetailRepo: ParameterDetailRepository,
    companyTerminalRepo: CompanyTerminalRepository,
}

export const ChargeSuppleTermsVM = ({ dispatch, companyChargeSuppleTermsRepo, masterDataRepo, shiftRepo, parameterDetailRepo, companyTerminalRepo }: ChargeSuppleTermsVMProps) => {
    const [companyDetailDispatch] = dispatch;

    const loadDropdownOption = async (companyId?: number) => {
        companyDetailDispatch(prevState => ({
            ...prevState,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                suppleTermsDropdownOptions: SupplementaryTermsDroOpts().getSuppleTermsDroOptsModel()
            }
        }))

        await masterDataRepo.getMasterDataByKey(MasterDataType.MODALITY).then(
            modalities => {
                const modalityDropdownOptions = modalities?.map((modality) => ({
                    dropdownLabel: modality.code,
                    tagLabel: modality.code,
                    value: modality.code,
                })) ?? []

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        modalityDropdownOptions: [
                            ...modalityDropdownOptions]
                    }
                }))
            }
        )

        await shiftRepo.getShiftByType(ShiftType.SHIFT_INTENSIVE).then(
            shiftList => {
                let shiftCodeDroOpts: DropdownProps[] = [];

                let newShiftList = _.orderBy(shiftList, ["shiftCode"]);
                newShiftList?.forEach(shift => {
                    const isShiftCodeExisted = shiftCodeDroOpts.find(t =>
                        t.value === shift.shiftCode);
                    if (!isShiftCodeExisted) {
                        shiftCodeDroOpts.push({
                            dropdownLabel: shift.shiftCode,
                            tagLabel: shift.shiftCode,
                            value: shift.shiftCode,
                        })
                    }
                })

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        shiftCodeDropdownOptions: shiftCodeDroOpts
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("BILLING_CYCLE").then(
            billingCycles => {
                let newBillingCycles = _.orderBy(billingCycles, ["parameterDtlCode"]);
                const billingCycleDroOpts = newBillingCycles?.map((billingCycle) => ({
                    dropdownLabel: billingCycle.parameterDtlCode,
                    tagLabel: billingCycle.parameterDtlCode,
                    value: billingCycle.parameterDtlCode,
                })) ?? [];

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billingCycleDropdownOptions: billingCycleDroOpts
                    }
                }))
            }
        )

        companyId && await companyTerminalRepo.searchCompanyTerminal(companyId).then(
            companyTerminals => {
                const operatingTmlDropdownOptions = companyTerminals?.map((companyTerminal) => ({
                    dropdownLabel: companyTerminal.terminal?.terminalCode as string,
                    tagLabel: companyTerminal.terminal?.terminalCode as string,
                    value: companyTerminal.terminal?.terminalCode as string,
                })) ?? []

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        operatingTmlDropdownOptions: [
                            ...operatingTmlDropdownOptions]
                    }
                }))
            }
        )
    }

    const onInitDefaultValue = async () => {
        companyDetailDispatch(prevState => {
            const compChgSuppleTerm = prevState.chargeSuppleTermsState.currentCompChgSuppleTerm;
            const suppleTermsDropOpts = prevState.dynamicOptions.suppleTermsDropdownOptions;
            const defaultSuppleTerms = compChgSuppleTerm.id !== 0 ? compChgSuppleTerm.supplementaryTerms : suppleTermsDropOpts[0].value

            onFieldChange("supplementaryTerms", defaultSuppleTerms);

            return {
                ...prevState,

                chargeSuppleTermsState: {
                    ...prevState.chargeSuppleTermsState,
                    currentCompChgSuppleTerm: {
                        ...prevState.chargeSuppleTermsState.currentCompChgSuppleTerm,
                        supplementaryTerms: defaultSuppleTerms
                    }
                }
            }
        });
    }

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        if (fieldKey === "supplementaryTerms") {
            onSuppleTermsChange(fieldKey, val);
        }

        if (fieldKey === "cntrMovement") {
            onCntrMovementChange(val);
        }

        companyDetailDispatch(prevState => ({
            ...prevState,
            chargeSuppleTermsState: {
                ...prevState.chargeSuppleTermsState,
                currentCompChgSuppleTerm: {
                    ...prevState.chargeSuppleTermsState.currentCompChgSuppleTerm,
                    [fieldKey]: val
                }
            }

        }))
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        companyDetailDispatch(prevState => ({
            ...prevState,
            chargeSuppleTermsState: {
                ...prevState.chargeSuppleTermsState,
                currentCompChgSuppleTerm: {
                    ...prevState.chargeSuppleTermsState.currentCompChgSuppleTerm,
                    [fieldName]: checked ? "Y" : "N"
                }
            }
        }))
    }

    const onSuppleTermsChange = async (fieldKey: string, fieldValue: string) => {
        if (fieldValue) {
            onDisabledFieldChange(fieldValue);

            initSuppTermsRefBySuppTerm(fieldValue);
            initCntrMovementBySuppTerm(fieldValue);
            initCntrStatusBySuppTerm(fieldValue);

            companyDetailDispatch(prevState => ({
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    allFormState: {}
                }
            }))
        }
    }

    const onDisabledFieldChange = async (suppleTerms: string) => {
        let isDisableSuppleTermRef = true;
        let isDisableCntrInfo = true;
        let isDisableChargeInfo = true;
        let isDisableModality = true;
        let validateSchemaType = "1";

        if (suppleTerms !== null && suppleTerms !== "") {
            if (suppleTerms === SupplementaryTermsPolicy.IN_YARD_DAY_LIMIT
                || suppleTerms === SupplementaryTermsPolicy.RAIL_IMO_SUR
                || suppleTerms === SupplementaryTermsPolicy.RAIL_RFR_SUR
                || suppleTerms === SupplementaryTermsPolicy.RAIL_OW_SUR
            ) {
                isDisableCntrInfo = false;
            } else if (suppleTerms === SupplementaryTermsPolicy.LATE_ARRIVAL_GRACE
                || suppleTerms === SupplementaryTermsPolicy.HANDLE_SHORTSEA_AS
            ) {
                isDisableSuppleTermRef = false;
            } else if (suppleTerms === SupplementaryTermsPolicy.INTENSIVE_SUR_PERC) {
                isDisableSuppleTermRef = false;
                isDisableCntrInfo = false;
                isDisableModality = false;
            } else if (suppleTerms === SupplementaryTermsPolicy.BILLING_CYCLE) {
                isDisableSuppleTermRef = false;
                isDisableChargeInfo = false;
            } else if (suppleTerms === SupplementaryTermsPolicy.BILLING_TYPE_INBOUND_NON_CY
                || suppleTerms === SupplementaryTermsPolicy.BILLING_TYPE_OUTBOUND_NON_CY
            ) {
                isDisableSuppleTermRef = false;
                isDisableCntrInfo = false;
            }

            if (suppleTerms === SupplementaryTermsPolicy.HANDLE_SHORTSEA_AS
                || suppleTerms === SupplementaryTermsPolicy.BILLING_CYCLE
                || suppleTerms === SupplementaryTermsPolicy.BILLING_TYPE_INBOUND_NON_CY
                || suppleTerms === SupplementaryTermsPolicy.BILLING_TYPE_OUTBOUND_NON_CY
            ) {
                validateSchemaType = "2";
            }

            companyDetailDispatch(prevState => ({
                ...prevState,

                chargeSuppleTermsState: {
                    ...prevState.chargeSuppleTermsState,
                    isDisableSuppleTermRef: isDisableSuppleTermRef,
                    isDisableCntrInfo: isDisableCntrInfo,
                    isDisableChargeInfo: isDisableChargeInfo,
                    isDisableModality: isDisableModality,
                    validateSchemaType: validateSchemaType as "1" | "2"
                }
            }))
        }
    }

    const initSuppTermsRefBySuppTerm = (suppTerms: string) => {
        companyDetailDispatch(prevState => {
            let suppleTermsRefDroOpts: DropdownProps[] = [];

            if (suppTerms === SupplementaryTermsPolicy.INTENSIVE_SUR_PERC) {
                suppleTermsRefDroOpts = prevState.dynamicOptions.shiftCodeDropdownOptions;
            } else if (suppTerms === SupplementaryTermsPolicy.LATE_ARRIVAL_GRACE) {
                suppleTermsRefDroOpts = SuppleTermsRefDroOpts().getLateArrGraceDroOptsModel();
            } else if (suppTerms === SupplementaryTermsPolicy.HANDLE_SHORTSEA_AS) {
                suppleTermsRefDroOpts = SuppleTermsRefDroOpts().getHandleShortSeaAsDroOptsModel();
            } else if (suppTerms === SupplementaryTermsPolicy.BILLING_TYPE_INBOUND_NON_CY) {
                suppleTermsRefDroOpts = BillingTypeDroOpts().getInboundDroOptsModel();
            } else if (suppTerms === SupplementaryTermsPolicy.BILLING_TYPE_OUTBOUND_NON_CY) {
                suppleTermsRefDroOpts = BillingTypeDroOpts().getOutBoundDroOptsModel();
            } else if (suppTerms === SupplementaryTermsPolicy.BILLING_CYCLE) {
                suppleTermsRefDroOpts = prevState.dynamicOptions.billingCycleDropdownOptions;
            }

            const defaultSuppleTermsRef = (suppleTermsRefDroOpts && suppleTermsRefDroOpts.length > 0) && prevState.viewState.isAdd ? suppleTermsRefDroOpts[0].tagLabel : prevState.chargeSuppleTermsState.currentCompChgSuppleTerm.supplementaryTermsRef;
            return {
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    suppleTermsRefDropdownOptions: suppleTermsRefDroOpts
                },
                chargeSuppleTermsState: {
                    ...prevState.chargeSuppleTermsState,
                    currentCompChgSuppleTerm: {
                        ...prevState.chargeSuppleTermsState.currentCompChgSuppleTerm,
                        supplementaryTermsRef: defaultSuppleTermsRef
                    }
                }
            }
        })
    }

    const initCntrMovementBySuppTerm = (suppTerms: string) => {
        let cntrMovementDroOpts: DropdownProps[] = [];

        if (suppTerms === SupplementaryTermsPolicy.INTENSIVE_SUR_PERC
            || suppTerms === SupplementaryTermsPolicy.RAIL_IMO_SUR
            || suppTerms === SupplementaryTermsPolicy.RAIL_OW_SUR
            || suppTerms === SupplementaryTermsPolicy.RAIL_RFR_SUR
            || suppTerms === SupplementaryTermsPolicy.IN_YARD_DAY_LIMIT
        ) {
            cntrMovementDroOpts = CntrMovementDroOpts().getDroOptsModel(true)
        } else {
            if (suppTerms === SupplementaryTermsPolicy.BILLING_TYPE_INBOUND_NON_CY) {
                cntrMovementDroOpts = CntrMovementDroOpts().getInBoundMovementDroOptsModel();
            } else if (suppTerms === SupplementaryTermsPolicy.BILLING_TYPE_OUTBOUND_NON_CY) {
                cntrMovementDroOpts = CntrMovementDroOpts().getOutBoundMovementDroOptsModel();
            }
        }

        companyDetailDispatch(prevState => ({
            ...prevState,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                cntrMovementDropdownOptions: cntrMovementDroOpts,
            },

            chargeSuppleTermsState: {
                ...prevState.chargeSuppleTermsState,
                currentCompChgSuppleTerm: {
                    ...prevState.chargeSuppleTermsState.currentCompChgSuppleTerm,
                    cntrMovement: null
                }
            }
        }))
    }

    const initCntrStatusBySuppTerm = (suppTerms: string) => {
        let cntrStatusDroOpts: DropdownProps[] = [];

        if (suppTerms === SupplementaryTermsPolicy.INTENSIVE_SUR_PERC
            || suppTerms === SupplementaryTermsPolicy.RAIL_IMO_SUR
            || suppTerms === SupplementaryTermsPolicy.RAIL_OW_SUR
            || suppTerms === SupplementaryTermsPolicy.RAIL_RFR_SUR
            || suppTerms === SupplementaryTermsPolicy.IN_YARD_DAY_LIMIT
        ) {
            cntrStatusDroOpts = CntrStatusDroOpts().getDroOptsModel();
        } else {
            if (suppTerms === SupplementaryTermsPolicy.BILLING_TYPE_INBOUND_NON_CY) {
                cntrStatusDroOpts = CntrStatusDroOpts().getDroOptsModelByMovementAndChgType("", "IB");
            } else if (suppTerms === SupplementaryTermsPolicy.BILLING_TYPE_OUTBOUND_NON_CY) {
                cntrStatusDroOpts = CntrStatusDroOpts().getDroOptsModelByMovementAndChgType("", "OB");
            }
        }

        companyDetailDispatch(prevState => ({
            ...prevState,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                cntrStatusDropdownOptions: cntrStatusDroOpts,
            },

            chargeSuppleTermsState: {
                ...prevState.chargeSuppleTermsState,
                currentCompChgSuppleTerm: {
                    ...prevState.chargeSuppleTermsState.currentCompChgSuppleTerm,
                    cntrStatus: null
                }
            }
        }));
    }

    const onCntrMovementChange = (cntrMovement: string) => {
        let cntrStatusDroOpts: DropdownProps[] = CntrStatusDroOpts().getDroOptsModelByMovementAndChgType("", cntrMovement);

        companyDetailDispatch(prevState => ({
            ...prevState,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                cntrStatusDropdownOptions: cntrStatusDroOpts
            },

            chargeSuppleTermsState: {
                ...prevState.chargeSuppleTermsState,
                currentCompChgSuppleTerm: {
                    ...prevState.chargeSuppleTermsState.currentCompChgSuppleTerm,
                    cntrStatus: null
                }
            }
        }))
    }

    const onSupplementaryTermsRefChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        return companyDetailDispatch(prevState => {
            val = prevState.dynamicOptions.suppleTermsRefDropdownOptions.find(item =>
                item.value === fieldValue)?.tagLabel ?? ''
            return {
                ...prevState,
                chargeSuppleTermsState: {
                    ...prevState.chargeSuppleTermsState,
                    currentCompChgSuppleTerm: {
                        ...prevState.chargeSuppleTermsState.currentCompChgSuppleTerm,
                        [fieldKey]: val
                    }
                }
            }
        });
    }

    const onAdd = () => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: true,
                viewState: {
                    ...prevState.viewState,
                    isAdd: true,
                    isEditable: false,
                    isSaveClicked: false,
                    isShowFsp: false,
                    isShowEmptyPool: false,
                    isShowSuppleTerms: true,
                    isShowCustAssign: false,
                    isShowFactor: false,
                    isShowCustInfo: false,
                    allFormState: {}
                },

                chargeSuppleTermsState: {
                    ...prevState.chargeSuppleTermsState,
                    selectedDatas:[],
                    currentCompChgSuppleTerm: {...EMPTY_COMPANY_CHARGE_SUPPLE_TERMS_ENTITY}
                }
            }
        });
    }

    const onEdit = (currentCompChgSuppleTerm: CompanyChargeSuppleTermsEntity) => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: true,
                viewState: {
                    ...prevState.viewState,
                    isAdd: false,
                    isEditable: prevState.viewState.isEditCompDtlInfo,
                    isSaveClicked: false,
                    isShowFsp: false,
                    isShowEmptyPool: false,
                    isShowSuppleTerms: true,
                    isShowCustAssign: false,
                    isShowFactor: false,
                    isShowCustInfo: false,
                    allFormState: {}
                },

                chargeSuppleTermsState: {
                    ...prevState.chargeSuppleTermsState,
                    currentCompChgSuppleTerm: currentCompChgSuppleTerm
                }
            }
        });
    }

    const handleDelete = () => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: false,

                viewState: {
                    ...prevState.viewState,
                    isShowDeleteModal: true,
                    dataType: "CHG_SUPPLE_TERMS"
                }
            }
        });
    }

    const onDelete = async (selectedRows: CompanyChargeSuppleTermsEntity[]) => {
        await companyChargeSuppleTermsRepo.deleteCompChargeSuppleTerms(
            selectedRows?.map(selectedRow => selectedRow.id) as number[]
        ).then((res) => {


            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    isShowRightPanel: false,
                    viewState: {
                        ...prevState.viewState,
                        isShowDeleteModal: false,
                        dataType: ""
                    },

                    chargeSuppleTermsState: {
                        ...prevState.chargeSuppleTermsState,
                        selectedDatas: [],
                        currentCompChgSuppleTerm: {...EMPTY_COMPANY_CHARGE_SUPPLE_TERMS_ENTITY}
                    }
                }
            });
        })
    }

    const onSubmit = async (selectedRows: CompanyChargeSuppleTermsEntity[]) => {
        const updatedRows = selectedRows?.map((selectedRow) => {
            return { ...selectedRow, status: 'FINALIZED', confirmedDate: null };
        });

        await companyChargeSuppleTermsRepo.batchUpdateCompChgSuppleTerms(updatedRows).then((res) => {


            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    isShowRightPanel: false,

                    chargeSuppleTermsState: {
                        ...prevState.chargeSuppleTermsState,
                        selectedDatas: [],
                        currentCompChgSuppleTerm: {...EMPTY_COMPANY_CHARGE_SUPPLE_TERMS_ENTITY}
                    }
                }
            });
        })
    }

    const handleReject = () => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: false,

                viewState: {
                    ...prevState.viewState,
                    isShowRejectModal: true,
                    dataType: "CHG_SUPPLE_TERMS"
                }
            }
        });
    }

    const onReject = async (selectedRows: CompanyChargeSuppleTermsEntity[], rejectReason: string) => {
        const updatedRows = selectedRows?.map((selectedRow) => {
            return { ...selectedRow, status: 'REJECTED', rejectReason: rejectReason };
        });

        await companyChargeSuppleTermsRepo.batchUpdateCompChgSuppleTerms(updatedRows).then((res) => {


            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    isShowRightPanel: false,
                    viewState: {
                        ...prevState.viewState,
                        isShowRejectModal: false,
                        dataType: "",
                        rejectReason: ""
                    },

                    chargeSuppleTermsState: {
                        ...prevState.chargeSuppleTermsState,
                        selectedDatas: [],
                        currentCompChgSuppleTerm: {...EMPTY_COMPANY_CHARGE_SUPPLE_TERMS_ENTITY}
                    }
                }
            });
        })
    }

    const onApprove = async (selectedRows: CompanyChargeSuppleTermsEntity[]) => {
        const confirmDate = new Date();
        const updatedRows = selectedRows?.map((selectedRow) => {
            return { ...selectedRow, confirmedDate: confirmDate, rejectReason: null };
        });

        await companyChargeSuppleTermsRepo.batchUpdateCompChgSuppleTerms(updatedRows).then((res) => {


            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    isShowRightPanel: false,

                    chargeSuppleTermsState: {
                        ...prevState.chargeSuppleTermsState,
                        selectedDatas: [],
                        currentCompChgSuppleTerm: {...EMPTY_COMPANY_CHARGE_SUPPLE_TERMS_ENTITY}
                    }
                }
            });
        })
    }

    const onSelectedCompSuppleTerms = (selectedRows: CompanyChargeSuppleTermsEntity[]) => {
        const isSingleSelRow = selectedRows && selectedRows.length === 1 ? true:false;

        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: isSingleSelRow?true:(prevState.viewState.isShowFsp || prevState.viewState.isShowEmptyPool ||
                     prevState.viewState.isShowCustAssign || prevState.viewState.isShowCustInfo || prevState.viewState.isShowFactor)?prevState.isShowRightPanel:false,
                viewState: {
                    ...prevState.viewState,
                    //isAdd: isSingleSelRow?false:prevState.viewState.isAdd,
                    isEditable: prevState.viewState.isEditCompDtlInfo,
                    isSaveClicked: isSingleSelRow?false:prevState.viewState.isSaveClicked,
                    isShowFsp: isSingleSelRow?false:prevState.viewState.isShowFsp,
                    isShowEmptyPool: isSingleSelRow?false:prevState.viewState.isShowEmptyPool,
                    isShowSuppleTerms: isSingleSelRow?true:false,
                    isShowCustAssign: isSingleSelRow?false:prevState.viewState.isShowCustAssign,
                    isShowFactor: isSingleSelRow?false:prevState.viewState.isShowFactor,
                    isShowCustInfo: isSingleSelRow?false:prevState.viewState.isShowCustInfo,
                    allFormState: isSingleSelRow?{}:prevState.viewState.allFormState
                },

                chargeSuppleTermsState: {
                    ...prevState.chargeSuppleTermsState,
                    selectedDatas: selectedRows,
                    forceRefresh: !prevState.chargeSuppleTermsState.forceRefresh,
                    currentCompChgSuppleTerm: isSingleSelRow?selectedRows[0]:{ ...EMPTY_COMPANY_CHARGE_SUPPLE_TERMS_ENTITY }
                }
            }
        });
    }

    const onSave = async (currentCompChgSuppleTerm: CompanyChargeSuppleTermsEntity, validateSchemaType: "1" | "2", isAdd: boolean) => {
        const valResult = await Validation(validateSchemaType === "2" ? commonCompChgSuppleTermValidationSchema_2 :
            commonCompChgSuppleTermValidationSchema_1).ValidateFormOnly(currentCompChgSuppleTerm);

        if (valResult) {
            let allValResult: { [x: string]: string } = {};

            if (valResult) {
                allValResult = {
                    ...allValResult, ...valResult,
                    mandatoryCheckFail: 'Please input the missing value.'
                };
            }


            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    chargeSuppleTermsState: {
                        ...prevState.chargeSuppleTermsState,
                        selectedDatas: [],
                        currentCompChgSuppleTerm: {...EMPTY_COMPANY_CHARGE_SUPPLE_TERMS_ENTITY}
                    },
                    viewState: {
                        ...prevState.viewState,
                        allFormState: {
                            ...allValResult,
                        },

                    }
                }
            });
            return { "saveChgSuppleTermsFailed": 'Please input the missing value.' };
        } else {
            return await (isAdd ? companyChargeSuppleTermsRepo.addCompChargeSuppleTerms(currentCompChgSuppleTerm) : companyChargeSuppleTermsRepo.updateCompChargeSuppleTerms(currentCompChgSuppleTerm)).then((data) => {
                if (data && data.toString().startsWith("Error:")) {
                    companyDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: { "saveChgSuppleTermsFailed": data.toString() }
                        };
                    });
                    return { "saveChgSuppleTermsFailed": data.toString() };
                } else {
                    return companyDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            isShowRightPanel: false,
                            viewState: {
                                ...prevState.viewState,
                                isAdd: false,
                                isRead: true,
                                isEditable: false,
                                isSaveClicked: false,
                                lastEditRowId: '',
                                isSliderOpen: false,
                                allFormState: {},

                                isShowFsp: false,
                                isShowEmptyPool: false,
                                isShowSuppleTerms: false,
                                isShowCustAssign: false,
                                isShowFactor: false,
                                isShowCustInfo: false
                            },

                            chargeSuppleTermsState: {
                                ...prevState.chargeSuppleTermsState,
                                selectedDatas: [],
                                currentCompChgSuppleTerm: {...EMPTY_COMPANY_CHARGE_SUPPLE_TERMS_ENTITY}
                            }
                        }
                    })
                }
            })
        }
    }

    const initialChgSuppleTermsTableData = async (compnayId: number) => {
        companyChargeSuppleTermsRepo.searchCompChargeSuppleTerms(compnayId).then(data => {
            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    compChargeSuppleTerms: data
                }
            })
        })
    }

    return {
        loadDropdownOption: loadDropdownOption,
        onAdd: onAdd,
        onEdit: onEdit,
        onDelete: onDelete,
        onSubmit: onSubmit,
        onReject: onReject,
        onApprove: onApprove,
        onSelectedCompSuppleTerms: onSelectedCompSuppleTerms,
        onFieldChange: onFieldChange,
        onCheckboxChange: onCheckboxChange,
        onSave: onSave,
        initialChgSuppleTermsTableData: initialChgSuppleTermsTableData,
        handleReject: handleReject,
        onSupplementaryTermsRefChange: onSupplementaryTermsRefChange,
        onInitDefaultValue: onInitDefaultValue,
        handleDelete: handleDelete,
        onSuppleTermsChange: onSuppleTermsChange
    }
}