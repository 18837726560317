import { SelectionChangedEvent } from "ag-grid-community";
import { CompanyConstant } from "presentation/constant/Company/CompanyConstant";
import { INITIAL_COMPANY_TEU_FACTOR_COL_DEF, transferRowData } from "presentation/constant/Company/CompanyTeuFactorColumnDefinition";
import { useCompanyTeuFactorVM } from "presentation/hook/Company/useCompanyTeuFactorVM";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";


const CompanyTeuFactorTablePanel: React.FC = () => {
    const [companyDetailState] = useCompanyDetailTracked();
    const companyTeuFactorVM = useCompanyTeuFactorVM();

    const [isLoading, setIsLoading] = useState(false);
    const COMPANY_DTL_CONST = CompanyConstant.Detail;
    const { isAdd, isEditable, isShowFsp, isShowEmptyPool, isShowSuppleTerms, isShowCustAssign, isShowCustInfo, isShowFactor } = companyDetailState.viewState;
    //const selectedDatas = companyDetailState.companyTeuFactorState.selectedDatas;
    // const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    let gridRef: any = useRef();

    const memoTableBtns = useMemo(() => {
        const tableBtns = [];

        tableBtns.push({
            id: 'onRefreshButton',
            icon: 'Icon-reload',
            title: 'Refresh'
        })

        return tableBtns
    }, [])

    const handleRefresh = useCallback(() => {
        setIsLoading(true);
        companyDetailState.companyId && companyTeuFactorVM.initialTeuFactorTableData(companyDetailState.companyId).then(() => {
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false)
        })
    }, [companyDetailState.companyId, companyTeuFactorVM]);

    // let performedClicks = 0;
    const handleSelectionChange = useCallback((e: SelectionChangedEvent, delay: number = 250) => {  
        if(isAdd) return;
              
        const selectedRows = e.api.getSelectedRows();
        companyTeuFactorVM.onSelectedTeuFactors(selectedRows);
    }, [companyTeuFactorVM, isAdd])

    const isRowSelectable = useCallback((params: any) => {
        if ((isShowFactor && (isEditable || isAdd))
                || isShowFsp || isShowEmptyPool || isShowSuppleTerms || isShowCustAssign || isShowCustInfo) {
            return false;            
        } else {
            return true;            
        }
    }, [isAdd, isEditable, isShowCustAssign, isShowCustInfo, isShowEmptyPool, isShowFactor, isShowFsp, isShowSuppleTerms]);

    const memoTableTitle = useMemo(() =>
        <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
            <HeaderTitle>{COMPANY_DTL_CONST.TEU_FACTOR.TEU_FACTOR}</HeaderTitle>
        </Sidebarheader>
        , [COMPANY_DTL_CONST.TEU_FACTOR.TEU_FACTOR])

    const memoCompanyTeuFactorTable = useMemo(() => {
        return (
            <div style={{ marginTop: "50px" }}>
                <NbisTable
                    id='company-teu-factor-table'
                    isNewColumnSetting={true}
                    columns={INITIAL_COMPANY_TEU_FACTOR_COL_DEF?.slice()}
                    data={transferRowData(companyDetailState.companyTeuFactors) ?? []}
                    headerActionButtons={memoTableBtns}
                    showPaginator={false}
                    editable={false}
                    showHeaderIcons={true}
                    showAddIcon={false}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    isScrollHighlighted={true}
                    isRowHighligted={true}
                    selectionMode={false}
                    rowSelection={"multiple"}
                    //onRowDoubleClick={(e: any, row: CompanyTeuFactorEntity) => handleEdit(row)}
                    handleSelectionChanged={(e: any) => handleSelectionChange(e, 400)}
                    onRefreshButton={handleRefresh}
                    //onEditButton={handleEdit}                
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 700px)"
                    ref={gridRef}
                    headerLabel={memoTableTitle}
                    handleRowSelectable={isRowSelectable}
                    showPivotMode={false}
                />
            </div>
        );
    }, [companyDetailState.companyTeuFactors, handleRefresh, handleSelectionChange, isRowSelectable, memoTableBtns, memoTableTitle])

    useEffect(() => {
        if (gridRef.current && gridRef.current.gridRef && gridRef.current.gridRef.current && gridRef.current.gridRef.current.api) {
            if (isShowFsp || isShowEmptyPool || isShowSuppleTerms || isShowCustAssign || isShowCustInfo || isAdd) {
                gridRef.current?.gridRef.current.api?.deselectAll();
                companyTeuFactorVM.removeSelectedRow();
            }
            gridRef.current.gridRef.current.api.redrawRows();
        }
    }, [isAdd, isEditable, isShowFactor, isShowCustAssign, isShowCustInfo, isShowEmptyPool, isShowFsp, isShowSuppleTerms, companyTeuFactorVM])

    return <><TableWrapper>{memoCompanyTeuFactorTable}</TableWrapper>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />} </>;
}

export default memo(CompanyTeuFactorTablePanel);
