import { ChargeTypePolicy } from "constants/charge/ChargeTypePolicy";
import { SubChargeTypePolicy } from "constants/charge/SubChargeTypePolicy";
import { CompanyFspEntity, EMPTY_COMPANY_FSP_ENTITY } from "domain/entity/Company/CompanyFspEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { CompanyFspRepository } from "domain/repository/Company/CompanyFspRepo";
import type { CompanyTerminalRepository } from "domain/repository/Company/CompanyTerminalRepo";
import { ForwarderCodeRepository } from "domain/repository/ForwarderCode/ForwarderCodeRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { OpsLineRepository } from "domain/repository/OpsLine/OpsLineRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import _ from "lodash";
import { commonCompFspValidationSchema } from "presentation/constant/Company/CompFspValidationSchema";
import { CntrMovementDroOpts } from "presentation/constant/DropDownOptions/Company/CntrMovementDroOpts";
import { CntrStatusDroOpts } from "presentation/constant/DropDownOptions/Company/CntrStatusDroOpts";
import { EventTypeDroOpts } from "presentation/constant/DropDownOptions/Company/EventTypeDroOpts";
import { FSPCountingDroOpts } from "presentation/constant/DropDownOptions/Company/FSPCountingDroOpts";
import { FSPDirectionDroOpts } from "presentation/constant/DropDownOptions/Company/FSPDirectionDroOpts";
import { FSPUomDroOpts } from "presentation/constant/DropDownOptions/Company/FSPUomDroOpts";
import { Validation } from "presentation/constant/Validation";
import { CompanyDetailModel } from "presentation/model/Company/CompanyDetailModel";
import { DropdownProps } from "presentation/model/DropdownProps";

import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";

interface CompanyFspVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<CompanyDetailModel>> | ((value: SetStateAction<CompanyDetailModel>) => void),
    ]
    companyFspRepo: CompanyFspRepository,
    parameterDetailRepo: ParameterDetailRepository,
    opsLineRepo: OpsLineRepository,
    chargeTypeRepo: ChargeTypeRepository,
    forwarderCodeRepo: ForwarderCodeRepository,
    masterDataRepo: MasterDataRepository,
    companyTerminalRepo: CompanyTerminalRepository,
}

export const CompanyFspVM = ({ dispatch, companyFspRepo, parameterDetailRepo, opsLineRepo, chargeTypeRepo,
    forwarderCodeRepo, masterDataRepo, companyTerminalRepo }: CompanyFspVMProps) => {
    const [companyDetailDispatch] = dispatch;

    const loadDropdownOption = async (companyId?: number) => {
        companyDetailDispatch(prevState => ({
            ...prevState,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                fspUomDropdownOptions: FSPUomDroOpts().getFSPUomModel(),
                calendarAdjDropdownOptions: EventTypeDroOpts().getEventTypeModel()
            }
        }))

        await masterDataRepo.getMasterDataByKey(MasterDataType.CONSORTIUM).then(
            consortiumCodes => {
                const consortiumCodeDropdownOptions = consortiumCodes?.map((consortiumCode) => ({
                    dropdownLabel: consortiumCode.code,
                    tagLabel: consortiumCode.code,
                    value: consortiumCode.code,
                })) ?? []

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        consortiumCodeDropdownOptions: [
                            ...consortiumCodeDropdownOptions]
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.SERVICE).then(
            serviceCodes => {
                const serviceCodeDropdownOptions = serviceCodes?.map((serviceCode) => ({
                    dropdownLabel: serviceCode.code,
                    tagLabel: serviceCode.code,
                    value: serviceCode.code,
                })) ?? []

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        serviceCodeDropdownOptions: [
                            ...serviceCodeDropdownOptions]
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("SHIPMENT_TYPE").then(
            shipmentTypes => {
                const shipmentTypeDropdownOptions = shipmentTypes?.map((shipmentType) => ({
                    dropdownLabel: shipmentType.parameterDtlCode,
                    tagLabel: shipmentType.parameterDtlCode,
                    value: shipmentType.parameterDtlCode,
                })) ?? []

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        shipmentTypeDropdownOptions: shipmentTypeDropdownOptions,
                    }
                }))
            }
        )

        await opsLineRepo.getAllOpsLines().then(
            opsLines => {
                const opsLinesDropdownOptions = opsLines?.map((opsLine) => ({
                    dropdownLabel: opsLine.opsLine,
                    tagLabel: opsLine.opsLine,
                    value: opsLine.opsLine,
                })) ?? []

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        opsLineDropdownOptions: opsLinesDropdownOptions
                    }
                }))
            }
        )

        await forwarderCodeRepo.getAllForwarderCodes().then(
            forwarderCodes => {
                const forwarderCodesDropdownOptions = forwarderCodes?.map((forwarderCode) => ({
                    dropdownLabel: forwarderCode.forwarderCode,
                    tagLabel: forwarderCode.forwarderCode,
                    value: forwarderCode.forwarderCode,
                })) ?? []

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        forwardCodeDropdownOptions: forwarderCodesDropdownOptions
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("CARGO_CODE").then(
            cargoCodes => {
                const cargoCodeDropdownOptions = cargoCodes?.map((cargoCode) => ({
                    dropdownLabel: cargoCode.parameterDtlCode,
                    tagLabel: cargoCode.parameterDtlCode,
                    value: cargoCode.parameterDtlCode,
                })) ?? []

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        cargoCodeDropdownOptions: cargoCodeDropdownOptions,
                    }
                }))
            }
        )

        await chargeTypeRepo.getFspChargeType().then(
            chargeTypes => {
                let filterChargeTypes = chargeTypes?.filter(chargeType => chargeType.countFspInd === 'Y');
                let newChargeTypes = _.orderBy(filterChargeTypes, ["chargeType", "subChargeType"]);
                let chargeTypeDropdownOptions: DropdownProps[] = [];
                let subChargeTypeDropdownOptions: { [key: string]: DropdownProps[] } = {};
                newChargeTypes?.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOptions.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOptions.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                    if (chgTypeEty.subChargeType) {
                        if (!subChargeTypeDropdownOptions[chgTypeEty.chargeType]) {
                            subChargeTypeDropdownOptions[chgTypeEty.chargeType] = [];
                        }
                        subChargeTypeDropdownOptions[chgTypeEty.chargeType].push({
                            dropdownLabel: chgTypeEty.subChargeType,
                            tagLabel: chgTypeEty.subChargeType,
                            value: chgTypeEty.subChargeType
                        });
                    }
                });

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        fspChargeTypeDropdownOptions: chargeTypeDropdownOptions,
                        fspSubChargeTypeDropdownOptions: subChargeTypeDropdownOptions
                    },
                    companyFspState: {
                        ...prevState.companyFspState,
                        currentCompanyFsp: {
                            ...prevState.companyFspState.currentCompanyFsp
                        }
                    }
                }))
            }
        )

        companyId && await companyTerminalRepo.searchCompanyTerminal(companyId).then(
            companyTerminals => {
                const operatingTmlDropdownOptions = companyTerminals?.map((companyTerminal) => ({
                    dropdownLabel: companyTerminal.terminal?.terminalCode as string,
                    tagLabel: companyTerminal.terminal?.terminalCode as string,
                    value: companyTerminal.terminal?.terminalCode as string,
                })) ?? []

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        operatingTmlDropdownOptions: [
                            ...operatingTmlDropdownOptions]
                    }
                }))
            }
        )
    }

    const onInitDefaultValue = () => {
        companyDetailDispatch(prevState => {
            const companyFsp = prevState.companyFspState.currentCompanyFsp;
            const fspChgTypeDroOpts = prevState.dynamicOptions.fspChargeTypeDropdownOptions;
            const fspUomDroOpts = prevState.dynamicOptions.fspUomDropdownOptions;
            const defaultChgType = companyFsp.id !== 0 ? companyFsp.chargeType : fspChgTypeDroOpts[0].value;

            onChargeTypeChange("chargeType", defaultChgType);

            return {
                ...prevState,
                companyFspState: {
                    ...prevState.companyFspState,
                    currentCompanyFsp: {
                        ...prevState.companyFspState.currentCompanyFsp,
                        fspUOM: companyFsp.id !== 0 ? prevState.companyFspState.currentCompanyFsp.fspUOM : fspUomDroOpts[0].value
                    }
                }
            }
        });

    }

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));

            if (val && (fieldKey === "owner" || fieldKey === "shipmentType"
                || fieldKey === "applyCalendarAdj")
            ) {
                val = val.join(",");
            }
        }

        return companyDetailDispatch(prevState => {
            return {
                ...prevState,
                companyFspState: {
                    ...prevState.companyFspState,
                    currentCompanyFsp: {
                        ...prevState.companyFspState.currentCompanyFsp,
                        [fieldKey]: val
                    }
                }
            }
        });
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        companyDetailDispatch(prevState => ({
            ...prevState,
            companyFspState: {
                ...prevState.companyFspState,
                currentCompanyFsp: {
                    ...prevState.companyFspState.currentCompanyFsp,
                    [fieldName]: checked ? "Y" : "N"
                }
            }
        }))
    }

    const onChargeTypeChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        const directionDroOpts = initFspDirection(val);

        const newChargeType = getChargeTypeForCC(val, "")
        const fspCountingFromDroOpts = initFspCountingFrom(newChargeType);
        const fspCountingToDroOpts = initFspCountingTo(newChargeType);
        const cntrMovementDroOpts = initCntrMovement(newChargeType)
        const cntrStatusDroOpts = initCntrStatus(newChargeType, "");

        companyDetailDispatch(prevState => {
            const companyFsp = prevState.companyFspState.currentCompanyFsp;

            let defFspCountingFrm;
            let defFspCountingTo;
            let defFspDirection;
            if (companyFsp && companyFsp.id !== 0) {
                defFspCountingFrm = companyFsp.fspCountingFrom;
                defFspCountingTo = companyFsp.fspCountingTo;
                defFspDirection = companyFsp.fspCountingDirection;
            } else {
                if (fspCountingFromDroOpts && fspCountingFromDroOpts.length > 0) {
                    defFspCountingFrm = fspCountingFromDroOpts[0].tagLabel;
                }
                if (fspCountingToDroOpts && fspCountingToDroOpts.length > 0) {
                    defFspCountingTo = fspCountingToDroOpts[0].tagLabel;
                }
                if (directionDroOpts && directionDroOpts.length > 0) {
                    defFspDirection = directionDroOpts[0].value;
                }
            }

            return {
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    directionDropdownOptions: directionDroOpts,
                    fspCountingFromDropdownOptions: fspCountingFromDroOpts,
                    fspCountingToDropdownOptions: fspCountingToDroOpts,
                    cntrMovementDropdownOptions: cntrMovementDroOpts,
                    cntrStatusDropdownOptions: cntrStatusDroOpts
                },
                companyFspState: {
                    ...prevState.companyFspState,
                    currentCompanyFsp: {
                        ...prevState.companyFspState.currentCompanyFsp,
                        [fieldKey]: val,
                        subChargeType: null,
                        fspCountingFrom: defFspCountingFrm ?? "",
                        fspCountingTo: defFspCountingTo ?? "",
                        fspCountingDirection: defFspDirection ?? ""
                    }
                }
            }
        });
    }

    const onSubChargeTypeChange = (fieldKey: string, chargeType: string, subChargeType: string) => {
        let val: any = subChargeType;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        const fspCountingFromDroOpts = initFspCountingFromBySubChgType(chargeType, subChargeType);
        const fspCountingToDroOpts = initFspCountingToBySubChgType(chargeType, subChargeType);

        return companyDetailDispatch(prevState => {
            return {
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    fspCountingFromDropdownOptions: fspCountingFromDroOpts,
                    fspCountingToDropdownOptions: fspCountingToDroOpts
                },
                companyFspState: {
                    ...prevState.companyFspState,
                    currentCompanyFsp: {
                        ...prevState.companyFspState.currentCompanyFsp,
                        [fieldKey]: val
                    }
                }
            }
        });
    }

    const onCntrMovementChange = (fieldKey: string, chargeType: string, cntrMovement: string) => {
        let val: any = cntrMovement;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        const newChargeType = getChargeTypeForCC(chargeType, "")
        const cntrStatusDroOpts = initCntrStatus(newChargeType, cntrMovement);

        return companyDetailDispatch(prevState => {
            return {
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    cntrStatusDropdownOptions: cntrStatusDroOpts
                },
                companyFspState: {
                    ...prevState.companyFspState,
                    currentCompanyFsp: {
                        ...prevState.companyFspState.currentCompanyFsp,
                        [fieldKey]: val
                    }
                }
            }
        });
    }

    const initFspDirection = (chargeType: string) => {
        return FSPDirectionDroOpts().getDirectionByChargeType(chargeType);
    }

    const initFspCountingFrom = (chargeType: string) => {
        return FSPCountingDroOpts().getFSPCountingFromByChargeType(chargeType);
    }

    const initFspCountingTo = (chargeType: string) => {
        return FSPCountingDroOpts().getFSPCountingToByChargeType(chargeType);
    }

    const initCntrMovement = (chargeType: string) => {
        if (chargeType === ChargeTypePolicy.CHARGE_TYPE_ITM
            || chargeType === ChargeTypePolicy.CHARGE_TYPE_OBS
            || chargeType === ChargeTypePolicy.CHARGE_TYPE_TSS
        ) {
            return CntrMovementDroOpts().getDroOptsModel(false);
        } else {
            return CntrMovementDroOpts().getDroOptsModel(true);
        }
    }

    const initCntrStatus = (chargeType: string, cntrMovement: string) => {
        return CntrStatusDroOpts().getDroOptsModelByMovementAndChgType(chargeType, cntrMovement);
    }

    const getChargeTypeForCC = (chargeType: string, subChargeType: string) => {
        let newChargeType;

        if (chargeType && chargeType === ChargeTypePolicy.CHARGE_TYPE_CC) {
            if (subChargeType === ChargeTypePolicy.CHARGE_TYPE_OBS
                || subChargeType === SubChargeTypePolicy.CODE_DEMX
            ) {
                newChargeType = ChargeTypePolicy.CHARGE_TYPE_OBS;
            } else if (subChargeType === SubChargeTypePolicy.CODE_ITM
                || subChargeType === SubChargeTypePolicy.CODE_DEMI
            ) {
                newChargeType = ChargeTypePolicy.CHARGE_TYPE_ITM;
            } else {
                newChargeType = chargeType;
            }
        } else {
            newChargeType = chargeType;
        }

        return newChargeType;
    }

    const initFspCountingFromBySubChgType = (chargeType: string, subChargeType: string) => {
        return FSPCountingDroOpts().getFSPCountingFromBySubChargeType(chargeType, subChargeType);
    }

    const initFspCountingToBySubChgType = (chargeType: string, subChargeType: string) => {
        return FSPCountingDroOpts().getFSPCountingToBySubChargeType(chargeType, subChargeType);
    }

    const onFspCountingChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        return companyDetailDispatch(prevState => {
            const chargeType = prevState.companyFspState.currentCompanyFsp.chargeType ?? "";

            if (fieldKey === "fspCountingFrom") {
                val = FSPCountingDroOpts().getFromKeyByValue(chargeType, val);

                if (val === null || val === "") {
                    const fspCountingFromDroOpts = prevState.dynamicOptions.fspCountingFromDropdownOptions;
                    if (fspCountingFromDroOpts && fspCountingFromDroOpts.length > 0) {
                        val = fspCountingFromDroOpts[0].tagLabel ?? "";
                    }
                }
            }

            if (fieldKey === "fspCountingTo") {
                val = FSPCountingDroOpts().getToKeyByValue(chargeType, val);

                if (val === null || val === "") {
                    const fspCountingToDroOpts = prevState.dynamicOptions.fspCountingToDropdownOptions;
                    if (fspCountingToDroOpts && fspCountingToDroOpts.length > 0) {
                        val = fspCountingToDroOpts[0].tagLabel ?? "";
                    }
                }
            }

            return {
                ...prevState,
                companyFspState: {
                    ...prevState.companyFspState,
                    currentCompanyFsp: {
                        ...prevState.companyFspState.currentCompanyFsp,
                        [fieldKey]: val
                    }
                }
            }
        });
    }

    const onTimePickerChange = (value: Date, hourName: string, minName: string) => {
        const hours = value.getHours();
        const mins = value.getMinutes();

        return companyDetailDispatch(prevState => {
            return {
                ...prevState,
                companyFspState: {
                    ...prevState.companyFspState,
                    currentCompanyFsp: {
                        ...prevState.companyFspState.currentCompanyFsp,
                        [hourName]: hours,
                        [minName]: mins
                    }
                }
            }
        });
    }

    const onAdd = () => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: true,
                viewState: {
                    ...prevState.viewState,
                    isAdd: true,
                    isEditable: false,
                    isSaveClicked: false,
                    isShowFsp: true,
                    isShowEmptyPool: false,
                    isShowSuppleTerms: false,
                    isShowCustAssign: false,
                    isShowFactor: false,
                    isShowCustInfo: false,
                    allFormState: {}
                },

                companyFspState: {
                    ...prevState.companyFspState,
                    selectedDatas:[],
                    currentCompanyFsp: {...EMPTY_COMPANY_FSP_ENTITY}
                }
            }
        });
    }

    const onEdit = (currentCompanyFsp: CompanyFspEntity) => {
        companyDetailDispatch(prevState => {

            return {
                ...prevState,
                isShowRightPanel: true,
                viewState: {
                    ...prevState.viewState,
                    isAdd: false,
                    isEditable: prevState.viewState.isEditCompDtlInfo,
                    isSaveClicked: false,
                    isShowFsp: true,
                    isShowEmptyPool: false,
                    isShowSuppleTerms: false,
                    isShowCustAssign: false,
                    isShowFactor: false,
                    isShowCustInfo: false,
                    allFormState: {}
                },

                companyFspState: {
                    ...prevState.companyFspState,
                    currentCompanyFsp: currentCompanyFsp
                }
            }
        });
    }

    const handleDelete = () => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: false,

                viewState: {
                    ...prevState.viewState,
                    isShowDeleteModal: true,
                    dataType: "FSP"
                }
            }
        });
    }

    const onDelete = async (selectedRows: CompanyFspEntity[]) => {
        await companyFspRepo.deleteCompanyFsp(
            selectedRows?.map(selectedRow => selectedRow.id) as number[]
        ).then((res) => {


            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    isShowRightPanel: false,
                    viewState: {
                        ...prevState.viewState,
                        isShowDeleteModal: false,
                        dataType: ""
                    },

                    companyFspState: {
                        ...prevState.companyFspState,
                        selectedDatas: [],
                        currentCompanyFsp: {...EMPTY_COMPANY_FSP_ENTITY}
                    }
                }
            });
        })
    }

    const onSubmit = async (selectedRows: CompanyFspEntity[]) => {
        const updatedRows = selectedRows?.map((selectedRow) => {
            return { ...selectedRow, status: 'FINALIZED', confirmedDate: null };
        });

        await companyFspRepo.batchUpdateCompanyFsp(updatedRows).then((res) => {


            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    isShowRightPanel: false,

                    companyFspState: {
                        ...prevState.companyFspState,
                        selectedDatas: [],
                        currentCompanyFsp: {...EMPTY_COMPANY_FSP_ENTITY}
                    }
                }
            });
        })
    }

    const handleReject = () => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: false,

                viewState: {
                    ...prevState.viewState,
                    isShowRejectModal: true,
                    dataType: "FSP"
                }
            }
        });
    }

    const onReject = async (selectedRows: CompanyFspEntity[], rejectReason: string) => {
        const updatedRows = selectedRows?.map((selectedRow) => {
            return { ...selectedRow, status: 'REJECTED', rejectReason: rejectReason };
        });

        await companyFspRepo.batchUpdateCompanyFsp(updatedRows).then((res) => {


            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    isShowRightPanel: false,

                    viewState: {
                        ...prevState.viewState,
                        isShowRejectModal: false,
                        dataType: "",
                        rejectReason: ""
                    },

                    companyFspState: {
                        ...prevState.companyFspState,
                        selectedDatas: [],
                        currentCompanyFsp: {...EMPTY_COMPANY_FSP_ENTITY}
                    }
                }
            });
        })
    }

    const onApprove = async (selectedRows: CompanyFspEntity[]) => {
        const confirmDate = new Date();
        const updatedRows = selectedRows?.map((selectedRow) => {
            return { ...selectedRow, confirmedDate: confirmDate, rejectReason: null };
        });

        await companyFspRepo.batchUpdateCompanyFsp(updatedRows).then((res) => {
            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    isShowRightPanel: false,

                    companyFspState: {
                        ...prevState.companyFspState,
                        selectedDatas: [],
                        currentCompanyFsp: {...EMPTY_COMPANY_FSP_ENTITY}
                    }
                }
            });
        })
    }

    const onApply = async (selectedRows: CompanyFspEntity[]) => {
        return await companyFspRepo.batchUpdateCompanyFsp(selectedRows);
    }

    const onSelectedCompFsps = (selectedRows: CompanyFspEntity[]) => {
        const isSingleSelRow = selectedRows && selectedRows.length === 1 ? true:false;

        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: isSingleSelRow?true:(prevState.viewState.isShowEmptyPool || prevState.viewState.isShowSuppleTerms ||
                    prevState.viewState.isShowCustAssign || prevState.viewState.isShowCustInfo || prevState.viewState.isShowFactor)?prevState.isShowRightPanel:false,
                viewState: {
                    ...prevState.viewState,
                    //isAdd: false,
                    isEditable: prevState.viewState.isEditCompDtlInfo,
                    isSaveClicked: false,
                    isShowFsp: isSingleSelRow?true:false,
                    isShowEmptyPool: isSingleSelRow?false:prevState.viewState.isShowEmptyPool,
                    isShowSuppleTerms: isSingleSelRow?false:prevState.viewState.isShowSuppleTerms,
                    isShowCustAssign: isSingleSelRow?false:prevState.viewState.isShowCustAssign,
                    isShowFactor: isSingleSelRow?false:prevState.viewState.isShowFactor,
                    isShowCustInfo: isSingleSelRow?false:prevState.viewState.isShowCustInfo,
                    allFormState: {}
                },

                companyFspState: {
                    ...prevState.companyFspState,
                    selectedDatas: selectedRows,
                    forceRefresh: !prevState.companyFspState.forceRefresh,
                    currentCompanyFsp: isSingleSelRow?selectedRows[0]:{ ...EMPTY_COMPANY_FSP_ENTITY }
                }
            }
        })
    }

    const onSave = async (currentCompanyFsp: CompanyFspEntity, isAdd: boolean) => {
        const valResult = await Validation(commonCompFspValidationSchema).ValidateFormOnly(currentCompanyFsp);

        if (valResult) {
            let allValResult: { [x: string]: string } = {};

            if (valResult) {
                allValResult = {
                    ...allValResult, ...valResult,
                    mandatoryCheckFail: 'Please input the missing value.'
                };
            }


            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    viewState: {
                        ...prevState.viewState,
                        allFormState: {
                            ...allValResult,
                        },

                    }
                }
            });
            return { "saveCompanyFspFailed": 'Please input the missing value.' };
        } else {
            await (isAdd ? companyFspRepo.addCompanyFsp(currentCompanyFsp) : companyFspRepo.updateCompanyFsp(currentCompanyFsp)).then((data) => {
                if (data && data.toString().startsWith("Error:")) {
                    companyDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: { "saveCompEmptyPoolFailed": data.toString() }
                        };
                    });
                    return { "saveCompEmptyPoolFailed": data.toString() };
                } else {
                    return companyDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            isShowRightPanel: false,
                            viewState: {
                                ...prevState.viewState,
                                isAdd: false,
                                isRead: true,
                                isEditable: false,
                                isSaveClicked: false,
                                lastEditRowId: '',
                                isSliderOpen: false,
                                allFormState: {},

                                isShowFsp: false,
                                isShowEmptyPool: false,
                                isShowSuppleTerms: false,
                                isShowCustAssign: false,
                                isShowFactor: false,
                                isShowCustInfo: false
                            },

                            companyFspState: {
                                ...prevState.companyFspState,
                                selectedDatas: [],
                                currentCompanyFsp: {...EMPTY_COMPANY_FSP_ENTITY}
                            }
                        }
                    })
                }
            })
        }
    }

    const initialFspTableData = async (compnayId: number) => {
        await companyFspRepo.searchCompanyFsp(compnayId).then(data => {
            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    companyFsps: data
                }
            })
        })
    }

    return {
        loadDropdownOption: loadDropdownOption,
        onAdd: onAdd,
        onEdit: onEdit,
        onDelete: onDelete,
        onSubmit: onSubmit,
        onReject: onReject,
        onApprove: onApprove,
        onApply: onApply,
        onSelectedCompFsps: onSelectedCompFsps,
        onFieldChange: onFieldChange,
        onTimePickerChange: onTimePickerChange,
        onSave: onSave,
        initialFspTableData: initialFspTableData,
        handleReject: handleReject,
        onChargeTypeChange: onChargeTypeChange,
        onSubChargeTypeChange: onSubChargeTypeChange,
        onCntrMovementChange: onCntrMovementChange,
        onFspCountingChange: onFspCountingChange,
        onInitDefaultValue: onInitDefaultValue,
        handleDelete: handleDelete,
        onCheckboxChange: onCheckboxChange
    }
}