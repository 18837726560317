import { CompanyTeuFactorEntity, EMPTY_COMPANY_TEU_FACTOR_ENTITY } from "domain/entity/Company/CompanyTeuFactorEntity";
import { CompanyTeuFactorRepository } from "domain/repository/Company/CompanyTeuFactorRepo";
import _ from "lodash";
import { commonCompTeuFactorValidationSchema } from "presentation/constant/Company/CompTeuFactorValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { CompanyDetailModel } from "presentation/model/Company/CompanyDetailModel";

import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";

interface CompanyTeuFactorVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<CompanyDetailModel>> | ((value: SetStateAction<CompanyDetailModel>) => void),
    ]
    companyTeuFactorRepo: CompanyTeuFactorRepository

}

export const CompanyTeuFactorVM = ({ dispatch, companyTeuFactorRepo }: CompanyTeuFactorVMProps) => {
    const [companyDetailDispatch] = dispatch;

    const loadDropdownOption = async (companyId: number) => {
        await companyTeuFactorRepo.searchCompTeuFactor(companyId).then(
            teuFactors => {
                const sizeDropdownOptions = teuFactors?.map((teuFactor) => ({
                    dropdownLabel: teuFactor.cntrSize as unknown as string,
                    tagLabel: teuFactor.cntrSize as unknown as string,
                    value: teuFactor.cntrSize as unknown as string,
                })) ?? []

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        sizeDropdownOptions: [
                            ...sizeDropdownOptions]
                    }
                }))
            }
        )
    }

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        return companyDetailDispatch(prevState => {
            var regex = null;
            if (fieldKey === 'teuFactor') {
                regex = /^\d*(\.\d{0,2})?$/;
                if (!regex.test(val)) {
                    val = prevState.companyTeuFactorState.currentCompTeuFactor[fieldKey];
                }
            }

            return {
                ...prevState,
                companyTeuFactorState: {
                    ...prevState.companyTeuFactorState,
                    currentCompTeuFactor: {
                        ...prevState.companyTeuFactorState.currentCompTeuFactor,
                        [fieldKey]: val
                    }
                }
            }
        });
    }

    const onEdit = (currentCompTeuFactor: CompanyTeuFactorEntity) => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: true,
                viewState: {
                    ...prevState.viewState,
                    isAdd: false,
                    isEditable: prevState.viewState.isEditCompDtlInfo,
                    isSaveClicked: false,
                    isShowFsp: false,
                    isShowEmptyPool: false,
                    isShowSuppleTerms: false,
                    isShowCustAssign: false,
                    isShowFactor: true,
                    isShowCustInfo: false,
                    allFormState: {}
                },

                companyTeuFactorState: {
                    ...prevState.companyTeuFactorState,
                    currentCompTeuFactor: currentCompTeuFactor
                }
            }
        });
    }

    const onSelectedTeuFactors = (selectedRows: CompanyTeuFactorEntity[]) => {
        const isSingleSelRow = selectedRows && selectedRows.length === 1 ? true:false;

        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: isSingleSelRow?true:(prevState.viewState.isShowEmptyPool || prevState.viewState.isShowSuppleTerms ||
                    prevState.viewState.isShowCustAssign || prevState.viewState.isShowCustInfo || prevState.viewState.isShowFsp)?prevState.isShowRightPanel:false,
                viewState: {
                    ...prevState.viewState,
                    //isAdd: false,
                    isEditable: prevState.viewState.isEditCompDtlInfo,
                    isSaveClicked: false,
                    isShowFsp: isSingleSelRow?false:prevState.viewState.isShowFsp,
                    isShowEmptyPool: isSingleSelRow?false:prevState.viewState.isShowEmptyPool,
                    isShowSuppleTerms: isSingleSelRow?false:prevState.viewState.isShowSuppleTerms,
                    isShowCustAssign: isSingleSelRow?false:prevState.viewState.isShowCustAssign,
                    isShowFactor: isSingleSelRow?true:false,
                    isShowCustInfo: isSingleSelRow?false:prevState.viewState.isShowCustInfo,
                    allFormState: {}
                },

                companyTeuFactorState: {
                    ...prevState.companyTeuFactorState,
                    selectedDatas: selectedRows,
                    currentCompTeuFactor: isSingleSelRow?selectedRows[0]:{ ...EMPTY_COMPANY_TEU_FACTOR_ENTITY }
                }
            }
        });
    }

    const onSave = async (currentCompanyTeuFactor: CompanyTeuFactorEntity, isAdd: boolean) => {
        const valResult = await Validation(commonCompTeuFactorValidationSchema).ValidateFormOnly(currentCompanyTeuFactor);

        if (valResult) {
            let allValResult: { [x: string]: string } = {};

            if (valResult) {
                allValResult = {
                    ...allValResult, ...valResult,
                    mandatoryCheckFail: 'Please input the missing value.'
                };
            }


            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    viewState: {
                        ...prevState.viewState,
                        allFormState: {
                            ...allValResult,
                        },

                    }
                }
            });
            return { "saveCompTeuFactorFailed": 'Please input the missing value.' };
        } else {
            await companyTeuFactorRepo.updateCompTeuFactor(currentCompanyTeuFactor).then((data) => {
                if (data && data.toString().startsWith("Error:")) {
                    companyDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: { "saveCompTeuFactorFailed": data.toString() }
                        };
                    });
                    return { "saveCompTeuFactorFailed": data.toString() };
                } else {

                    return companyDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            isShowRightPanel: false,
                            viewState: {
                                ...prevState.viewState,
                                isAdd: false,
                                isRead: true,
                                isEditable: false,
                                isSaveClicked: false,
                                lastEditRowId: '',
                                isSliderOpen: false,
                                allFormState: {},

                                isShowFsp: false,
                                isShowEmptyPool: false,
                                isShowSuppleTerms: false,
                                isShowCustAssign: false,
                                isShowFactor: false,
                                isShowCustInfo: false
                            },

                            companyTeuFactorState: {
                                ...prevState.companyTeuFactorState,
                                selectedDatas: [],
                                currentCompTeuFactor: { ...EMPTY_COMPANY_TEU_FACTOR_ENTITY }
                            }
                        }
                    })
                }
            })
        }
    }

    const initialTeuFactorTableData = async (compnayId: number) => {
        companyTeuFactorRepo.searchCompTeuFactor(compnayId).then(data => {
            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    companyTeuFactors: data
                }
            })
        })
    }

    const removeSelectedRow = () => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,                

                companyTeuFactorState: {
                    ...prevState.companyTeuFactorState,
                    selectedDatas: [],
                    currentCompTeuFactor: { ...EMPTY_COMPANY_TEU_FACTOR_ENTITY }
                }
            }
        });
    }

    return {
        loadDropdownOption: loadDropdownOption,
        onEdit: onEdit,
        onSelectedTeuFactors: onSelectedTeuFactors,
        onFieldChange: onFieldChange,
        onSave: onSave,
        initialTeuFactorTableData: initialTeuFactorTableData,
        removeSelectedRow: removeSelectedRow
    }
}